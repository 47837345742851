import { Box, Typography, useTheme } from '@mui/material';

import { getMasterPhotoById, getTeamImageById } from '@/helpers/images-helper';
import { getPlayerIcon } from '@/helpers/players-helper';

import CustomImageComponent from '../custom/custom-image';
import PositionBox from '../position-box';

const MinutesActionsCardPlayerComponent = ({ player }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.analitica.main,
                padding: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
                borderRadius: '2px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CustomImageComponent
                    src={getMasterPhotoById(player?.information?.id, 192)}
                    alt={player?.information?.name}
                    width={100}
                    height={100}
                    priority={true}
                    style={{ borderRadius: '50%', border: '2px solid #fff' }}
                />
            </Box>

            <Box>
                <Typography fontWeight="600" color="#fff">
                    {player?.information?.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography color="#fff">{player?.team?.name}</Typography>
                    <CustomImageComponent
                        src={getTeamImageById(player?.team?.id ?? player?.teamId)}
                        alt={'Escudo equipo'}
                        width={30}
                        height={30}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PositionBox
                        positionId={player?.information?.positionId}
                        fontSize={14}
                        styles={{ width: '24px', height: '24px', borderRadius: '50%' }}
                    />
                    {getPlayerIcon(player?.generalStatus?.status, player?.generalStatus?.statusLevel, 24, 24)}
                </Box>
            </Box>
        </Box>
    );
};

export default MinutesActionsCardPlayerComponent;
