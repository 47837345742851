import { LEAGUES_SEO_NAME } from '@/constants/leagues';
import { Rutas } from '@/constants/rutas';
import { currentSeason } from '@/constants/site';

export const navLinks = [
    {
        title: 'Inicio',
        path: Rutas.INICIO,
        id: 'inicio',
        navigate: true,
    },
    {
        title: 'Mercado',
        path: Rutas.MERCADO,
        cName: 'dropdown-link',
        id: 'mercado',
        navigate: true,
    },
    {
        title: 'Puja Ideal',
        path: Rutas.PUJA_FANTASY,
        id: 'puja-fantasy',
        navigate: true,
    },
    {
        title: 'Panel Premium',
        path: Rutas.PREMIUM,
        id: 'premium',
        navigate: true,
    },
    {
        title: 'Oráculo Fantasy',
        path: Rutas.ORACULO_FANTASY,
        id: 'oraculo-fantasy',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Alineaciones probables',
        path: Rutas.ALINEACIONES_PROBABLES,
        id: 'alineaciones-probables',
        navigate: true,
    },
    {
        title: 'Gestiona tus Tierlists',
        path: Rutas.TIER_LIST,
        id: 'tier-list',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Actividades',
        path: Rutas.ACTIVIDADES,
        id: 'actividades',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Noticias Flash',
        path: Rutas.NOTICIAS_FLASH,
        id: 'noticias-flash',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Mis Plantillas Fantasy',
        path: Rutas.MIS_PLANTILLAS,
        cName: 'dropdown-link',
        id: 'mis-plantillas',
        navigate: true,
    },
    {
        title: 'Analiza tu plantilla',
        path: Rutas.ANALIZA_TU_PLANTILLA,
        cName: 'dropdown-link',
        id: 'analiza-tu-plantilla',
        navigate: true,
        logged: false,
    },
    {
        title: 'Calendario Fantasy',
        path: Rutas.CALENDARIO_FANTASY,
        id: 'fantasy-la-liga-calendario',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Tierlists de Analítica Fantasy',
        path: Rutas.TIER_LIST_PUBLIC,
        id: 'public-tierlist',
        navigate: true,
        hideInDesktop: true,
        new: true,
    },
    {
        title: 'Lanzadores de penaltis',
        path: Rutas.LANZADORES_PENALTIS,
        id: 'lanzadores-penaltis',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Fantasy Relevo',
        path: Rutas.ESTADISTICAS_FANTASY_JUGADORES,
        id: 'fantasy-la-liga',
        navigate: false,
        subMenu: [
            {
                title: 'Mercado',
                path: Rutas.MERCADO,
                id: 'mercado',
                navigate: true,
            },
            {
                title: 'Puja Ideal',
                path: Rutas.PUJA_FANTASY,
                id: 'puja-fantasy',
                navigate: true,
            },
            {
                title: 'Estadísticas Fantasy',
                path: Rutas.ESTADISTICAS_FANTASY_JUGADORES,
                id: 'fantasy-la-liga-estadisticas',
                navigate: true,
            },
            {
                title: 'Mejores rachas',
                path: Rutas.MEJORES_RACHAS,
                id: 'fantasy-la-liga-rachas',
                navigate: true,
            },
            {
                title: 'Líderes por estadísticas',
                path: Rutas.LIDERES_ESTADISTICAS,
                cName: 'dropdown-link',
                id: 'lideres-estadisticas',
                navigate: true,
                logged: false,
            },
            {
                title: 'Comparador de jugadores',
                path: Rutas.COMPARADOR_DE_JUGADORES,
                id: 'fantasy-la-liga-comparador',
                navigate: true,
            },
            {
                title: 'Chollos fantasy relevo',
                path: Rutas.CHOLLOS_FANTASY_RELEVO,
                id: 'chollos',
                navigate: true,
            },
        ],
    },
    {
        title: 'Puntuaciones Jornada',
        path: Rutas.PUNTUACIONES_FANTASY,
        id: 'puntuaciones-jornada',
        navigate: true,
        new: true,
        hideInDesktop: true,
    },
    {
        title: 'Biwenger',
        path: Rutas.MERCADO_BIWENGER,
        id: 'biwenger',
        navigate: false,
        subMenu: [
            {
                title: 'Mercado',
                path: Rutas.MERCADO_BIWENGER,
                id: 'mercado-biwenger',
                navigate: true,
            },
            {
                title: 'Puja Ideal',
                path: Rutas.PUJA_FANTASY_BIWENGER,
                id: 'puja-fantasy-biwenger',
                navigate: true,
            },
            {
                title: 'Estadísticas',
                path: Rutas.ESTADISTICAS_BIWENGER,
                id: 'estadisticas-biwenger',
                navigate: true,
            },
            {
                title: 'Chollos fantasy',
                path: Rutas.CHOLLOS_FANTASY_BIWENGER,
                id: 'chollos',
                navigate: true,
            },
        ],
    },
    {
        title: 'Jugadores',
        path: Rutas.ALINEACIONES_PROBABLES,
        id: 'players-status',
        navigate: false,
        hideInDesktop: true,
        subMenu: [
            {
                title: 'Lesionados',
                path: Rutas.JUGADORES_LESIONADOS,
                cName: 'dropdown-link',
                id: 'jugadores-lesionados',
                navigate: true,
            },
            {
                title: 'Sancionados',
                path: Rutas.JUGADORES_SANCIONADOS,
                cName: 'dropdown-link',
                id: 'jugadores-sancionados',
                navigate: true,
            },
            {
                title: 'Dudas',
                path: Rutas.JUGADORES_DUDAS,
                cName: 'dropdown-link',
                id: 'jugadores-dudas',
                navigate: true,
            },
            {
                title: 'Apercibidos',
                path: Rutas.JUGADORES_APERCIBIDOS,
                cName: 'dropdown-link',
                id: 'jugadores-apercibidos',
                navigate: true,
            },
        ],
    },
    {
        title: 'Predicting Manager',
        path: Rutas.PREDICTING_MANAGER,
        cName: 'dropdown-link',
        id: 'predicting-manager',
        navigate: true,
        logged: false,
    },
    {
        title: 'General',
        id: 'la-liga',
        navigate: false,
        hideInDesktop: true,
        subMenu: [
            {
                title: 'Calendario',
                path: Rutas.CALENDARIO_COMPETICIONES,
                cName: 'dropdown-link',
                id: 'calendar-competitions',
                navigate: true,
            },
            {
                title: 'Clasificación',
                path: `${Rutas.CLASIFICACION}/${LEAGUES_SEO_NAME.LA_LIGA}/${currentSeason}`,
                cName: 'dropdown-link',
                id: 'clasification-competitions',
                navigate: true,
            },
            {
                title: 'Virus FIFA',
                path: Rutas.VIRUS_FIFA,
                cName: 'dropdown-link',
                id: 'virus-fifa',
                navigate: true,
            },
            {
                title: 'Salón de la fama',
                path: Rutas.SALON_DE_LA_FAMA,
                cName: 'dropdown-link',
                id: 'salon-de-la-fama',
                navigate: true,
            },
        ],
    },
    {
        title: 'Administrador',
        path: Rutas.ADMIN,
        isAdmin: true,
        id: 'admin',
        navigate: false,
        hideInDesktop: true,
        subMenu: [
            {
                title: 'Noticias Flash',
                path: Rutas.NOTICIAS_FLASH_ADMIN,
                cName: 'dropdown-link',
                id: 'admin-noticias-flash',
                navigate: true,
            },
            {
                title: 'Parte médico',
                path: Rutas.PARTE_MEDICO,
                cName: 'dropdown-link',
                id: 'administrador-parte-medico',
                navigate: true,
            },
            {
                title: 'Alineaciones probables',
                path: Rutas.ADMIN_ALINEACIONES_PROBABLES,
                cName: 'dropdown-link',
                id: 'alineaciones-probables',
                navigate: true,
                logged: false,
            },
            {
                title: 'Alineaciones confirmadas',
                path: Rutas.ADMINISTRADOR_CONVOCATORIAS,
                cName: 'dropdown-link',
                id: 'administrador-convocatorias',
                navigate: true,
                logged: false,
            },
            {
                title: 'Once tipo',
                path: Rutas.ONCE_TIPO,
                cName: 'dropdown-link',
                id: 'administrador-once-tipo',
                navigate: true,
                logged: false,
            },
            {
                title: 'Calendario administrador',
                path: Rutas.CALENDARIO_ADMIN,
                cName: 'dropdown-link',
                id: 'administrador-calendario-admin',
                navigate: true,
                logged: false,
            },
            {
                title: 'Administrador de Tierlists',
                path: Rutas.TIER_LIST_ADMIN,
                cName: 'dropdown-link',
                id: 'tier-list',
                navigate: true,
                logged: false,
            },
            {
                title: 'Análisis de partidos',
                path: Rutas.ADMINISTRADOR_ANALISIS_PARTIDO,
                cName: 'dropdown-link',
                id: 'premium',
                navigate: true,
                logged: false,
            },
        ],
    },
    {
        title: 'Configuración',
        path: Rutas.CONFIGURATION,
        isAdmin: true,
        id: 'configuration',
        navigate: false,
        hideInDesktop: true,
        subMenu: [
            {
                title: 'Configuración de Twitch',
                path: Rutas.CONFIGURATION,
                cName: 'dropdown-link',
                id: 'configuration-twitch',
                navigate: true,
            },
            {
                title: 'Jugadores',
                path: Rutas.ADMINISTRADOR_JUGADORES,
                cName: 'dropdown-link',
                id: 'administrador-jugadores',
                navigate: true,
            },
            {
                title: 'Imágenes',
                path: Rutas.ADMINISTRADOR_IMAGENES,
                cName: 'dropdown-link',
                id: 'administrador-imagenes',
                navigate: true,
            },
            {
                title: 'Buscador de jugadores',
                path: Rutas.BUSCADOR_JUGADORES,
                cName: 'dropdown-link',
                id: 'administrador-buscador-jugadores',
                navigate: true,
            },
            {
                title: 'Cuentas de usuario',
                path: Rutas.ACCOUNTS,
                cName: 'dropdown-link',
                id: 'cuentas-de-usuario',
                navigate: true,
            },
            {
                title: 'Administrador de partidos',
                path: Rutas.ADMINISTRADOR_PARTIDOS,
                cName: 'dropdown-link',
                id: 'administrador-de-partidos',
                navigate: true,
            },
            {
                title: 'Configuraciones generales',
                path: Rutas.ADMINISTRADOR_CONFIGURACIONES,
                cName: 'dropdown-link',
                id: 'configuraciones-generales',
                navigate: true,
            },
        ],
    },
    {
        title: 'Instala la app',
        path: Rutas.INSTALL_APP,
        id: 'app',
        navigate: true,
        hideInDesktop: true,
    },
    {
        title: 'Novedades',
        path: Rutas.NOVEDADES,
        id: 'novedades',
        navigate: true,
        hideInDesktop: true,
    },
];

export const footerLinks = [
    {
        title: 'Colaboradores',
        path: Rutas.COLABORADORES,
        id: 'colaboradores',
        navigate: true,
    },
    {
        title: 'Encuesta de satisfacción',
        path: Rutas.ENCUESTA_DE_SATISFACCION,
        id: 'encuesta-de-satisfaccion',
        navigate: true,
    },
    {
        title: 'Contáctanos',
        path: Rutas.CONTACTANOS,
        id: 'contactanos',
        navigate: true,
    },
    {
        title: 'Política de privacidad',
        path: Rutas.POLITICAS_PRIVACIDAD,
        id: 'politica-de-privacidad',
        navigate: true,
    },
    {
        title: 'Términos y condiciones',
        path: Rutas.TERMINOS_CONDICIONES,
        id: 'terminos-y-condiciones',
        navigate: true,
    },
    {
        title: '@AnaliticaLaLiga',
        path: 'https://twitter.com/AnaliticaLaLiga',
        id: 'twitter-analiticalfm',
        navigate: true,
    },
    {
        title: 'Kit digital',
        path: Rutas.KIT_DIGITAL,
        id: 'kit-digital',
        navigate: true,
    },
    {
        title: 'Creadores de contenido',
        path: Rutas.CREADORES_CONTENIDO,
        id: 'creadores-contenido',
        navigate: true,
    },
    {
        title: 'Sitios recomendados',
        path: Rutas.SITIOS_RECOMENDADOS,
        id: 'sitios-recomendados',
        navigate: true,
    },
];
