import React, { useEffect, useState } from 'react';

import { Box, Tabs, Tab } from '@mui/material';

import CustomImageComponent from '@/components/shared/custom/custom-image';
import { getCompetitionImage, getCompetitionName } from '@/helpers/leagues-helper';

const MinutesActionsTabsPlayerComponent = ({ partidos, selectedLeague, setSelectedLeague }) => {
    const [leagues, setLeagues] = useState([]);
    const [tabWidth, setTabWidth] = useState('100%');
    useEffect(() => {
        const leagueSet = new Set();
        partidos?.forEach((partido) => {
            leagueSet.add(partido.leagueId);
        });

        setLeagues(['all', ...Array.from(leagueSet)]);

        const width = `${100 / (leagueSet.size + 1)}%`;
        setTabWidth(width);
    }, [partidos, selectedLeague]);

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs
                value={selectedLeague}
                onChange={(_, newValue) => setSelectedLeague(newValue)}
                variant="scrollable"
                scrollButtons="auto"
            >
                {leagues.map((leagueId) => (
                    <Tab
                        key={leagueId}
                        value={leagueId}
                        sx={{ width: tabWidth, minWidth: tabWidth, padding: 0 }}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {leagueId !== 'all' && (
                                    <CustomImageComponent
                                        src={getCompetitionImage(leagueId)}
                                        alt={getCompetitionName(leagueId)}
                                        width={20}
                                        height={20}
                                    />
                                )}
                                {leagueId === 'all' && 'Todas'}
                            </Box>
                        }
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default MinutesActionsTabsPlayerComponent;
