import React from 'react';

import { Box } from '@mui/material';

import { translate } from '@/data/fixture-transalations';

import WrapperBarComponent from '../charts/BarComponent/wrapper-bar-component';

const MinutesActionsGraphicalPlayerComponent = ({ partidos }) => {
    const jornadas = partidos.map((item) => translate(item?.round, true));
    const minutos = partidos.map((item) => Number(item?.minutes ?? 0));

    const azul = 'rgba(25, 118, 210, 0.7)';
    const verde = 'rgba(75, 192, 75, 0.7)';
    const naranja = 'rgba(255, 165, 0, 0.7)'; // Naranja
    const rojo = 'rgba(255, 99, 132, 0.7)'; // Rojo

    // Función para determinar el color de cada barra
    const getBarColor = (minutos) => {
        if (minutos >= 90) return azul; // Azul
        if (minutos >= 60) return verde; // Verde
        if (minutos >= 45) return naranja; // Naranja
        return rojo; // Rojo
    };

    const colors = [
        { key: '90 minutos', value: azul },
        { key: '>60 minutos', value: verde },
        { key: '>45 minutos', value: naranja },
        { key: '<45 minutos', value: rojo },
    ];

    const backgroundColors = minutos.map(getBarColor);

    const chartData = {
        labels: jornadas,
        datasets: [
            {
                label: 'Minutos disputados',
                data: minutos,
                backgroundColor: backgroundColors, // Aplica colores dinámicos
                borderColor: 'rgba(0, 0, 0, 0.3)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Minutos disputados por jornada',
            },
        },
    };

    return (
        <Box style={{ width: '100%', padding: '10px', boxShadow: '0 2px 8px rgba(0,0,0,.1)' }}>
            <WrapperBarComponent text={''} data={chartData} options={options} />

            <Box display={'flex'} justifyContent={'space-between'} mt={2}>
                {colors.map((item, index) => (
                    <Box
                        key={index}
                        display={'flex'}
                        flexWrap={'wrap'}
                        gap={0.5}
                        alignItems={'center'}
                        fontSize={12}
                    >
                        <Box
                            sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: item.value,
                                borderRadius: '4px',
                            }}
                        />
                        <span>{item.key}</span>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default MinutesActionsGraphicalPlayerComponent;
