import { Box, useMediaQuery } from '@mui/material';

import FixturesPreview from '../equipos/sections/fixtures-preview';

const NextRivalsTablesComponent = ({ player, isDrawer = false }) => {
    const teamId = player?.team?.id ?? player?.teamId;
    const isMdScreen = useMediaQuery('(max-width: 768px)');
    return (
        <Box
            p={0.2}
            display={'flex'}
            flexDirection={isMdScreen || isDrawer ? 'column' : 'row'}
            gap={2}
            width={'100%'}
        >
            <FixturesPreview
                fixtures={player?.fixtures?.lastFixtures?.slice(0, 5)}
                title="Últimos partidos"
                mostrarVerMas={false}
                styles={{ width: isMdScreen || isDrawer ? '100%' : '50%' }}
                id={teamId}
            />

            <FixturesPreview
                fixtures={player?.fixtures?.upcomingFixtures?.slice(0, 5)}
                title="Próximos partidos"
                mostrarVerMas={false}
                styles={{ width: isMdScreen || isDrawer ? '100%' : '50%' }}
                id={teamId}
            />
        </Box>
    );
};

export default NextRivalsTablesComponent;
