const TipsIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 15}
            height={iconHeight ?? iconWidth ?? 15}
            viewBox="0 0 16 16"
        >
            <g fill="none">
                <path
                    fill="url(#IconifyId194ae3fd5b22aded03)"
                    d="M9 4a5 5 0 0 1 4.533 7.113l.457 2.289a.5.5 0 0 1-.588.588l-2.288-.457A5 5 0 1 1 9 4"
                />
                <path
                    fill="url(#IconifyId194ae3fd5b22aded00)"
                    d="M7 2a5 5 0 0 0-4.533 7.113l-.457 2.289a.5.5 0 0 0 .588.588l2.288-.457A5 5 0 1 0 7 2"
                />
                <path
                    fill="url(#IconifyId194ae3fd5b22aded01)"
                    d="M6.379 9.501a.625.625 0 1 1 1.249 0a.625.625 0 0 1-1.25 0"
                />
                <path
                    fill="url(#IconifyId194ae3fd5b22aded02)"
                    d="M5.15 5.954C5.14 4.817 5.955 4 7.003 4c1.031 0 1.854.846 1.854 1.95c0 .585-.215.907-.728 1.318l-.277.214c-.246.195-.328.3-.345.449l-.012.156a.5.5 0 0 1-.992-.09c0-.571.21-.884.716-1.289l.278-.215c.289-.229.36-.342.36-.544c0-.557-.381-.949-.854-.949c-.493 0-.859.366-.853.945a.5.5 0 1 1-1 .01"
                />
                <defs>
                    <linearGradient
                        id="IconifyId194ae3fd5b22aded00"
                        x1="2"
                        x2="12"
                        y1="2"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#0FAFFF" />
                        <stop offset="1" stopColor="#2764E7" />
                    </linearGradient>
                    <linearGradient
                        id="IconifyId194ae3fd5b22aded01"
                        x1="5.474"
                        x2="7.092"
                        y1="4.108"
                        y2="10.417"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FDFDFD" />
                        <stop offset="1" stopColor="#CCEAFF" />
                    </linearGradient>
                    <linearGradient
                        id="IconifyId194ae3fd5b22aded02"
                        x1="5.474"
                        x2="7.092"
                        y1="4.108"
                        y2="10.417"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FDFDFD" />
                        <stop offset="1" stopColor="#CCEAFF" />
                    </linearGradient>
                    <radialGradient
                        id="IconifyId194ae3fd5b22aded03"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="matrix(4.60184 5.3384 -5.34296 4.60577 6.684 6.67)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset=".63" stopColor="#3D35B1" />
                        <stop offset=".85" stopColor="#6553C9" />
                        <stop offset="1" stopColor="#7660D3" />
                    </radialGradient>
                </defs>
            </g>
        </svg>
    );
};

export default TipsIcon;
