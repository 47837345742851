import { useState, useContext } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';

import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { TwitchConstants } from '@/constants/twitch-constants';
import { Context } from '@/context';

import LogoutIcon from './icons/logout-icon';
import PremiumIcon from './icons/subscriptions/premium';
import TelegramIcon from './icons/telegram-icon';
import TelegramIcons from './icons/telegram-icons';
import Tiktok from './icons/tiktok';
import Twitch from './icons/twitch';
import TwitterIcon from './icons/twitter-icon';
import UserProfileIcon from './icons/user-profile-icon';
import WhatsappIcon from './icons/whatsapp-icon';

const SocialComponent = ({ handleClose }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { state, dispatch } = useContext(Context);
    const { user, configuration } = state;
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        dispatch({ type: REDUCERS.LOGGED_OUT_USER });
        handleClose();
    };

    const getTwitchConfiguration = () => {
        if (configuration?.twitchOption === TwitchConstants.LIVE) {
            return (
                <div
                    className="twitch-live"
                    onClick={() => window.open('https://www.twitch.tv/donquijotedlc', '_blank')}
                >
                    <Twitch color="#a970ff" width={18} height={18} />

                    <p className="twitch-live__title">En directo</p>
                </div>
            );
        }

        return (
            <div className="twitch-live">
                <Twitch
                    color="#fff"
                    width={18}
                    height={18}
                    onClick={() => window.open('https://www.twitch.tv/donquijotedlc', '_blank')}
                />
            </div>
        );
    };

    return (
        <div className="social-networks-container">
            <div className="new-bar">
                <div className="__left">
                    <div className="social-networks-text text-right social-networks-text-left-first-row-right">
                        <TwitterIcon
                            onClick={() => window.open('https://twitter.com/AnaliticaLaLiga', '_blank')}
                        />
                        <div
                            onClick={() => window.open('https://www.tiktok.com/@analitica.fantasy', '_blank')}
                        >
                            <Tiktok color={'#fff'} width={18} height={18} />
                        </div>
                        <TelegramIcon
                            onClick={() => window.open('https://t.me/+pOyVGp5tSEpkMzBh', '_blank')}
                        />
                        <TelegramIcons
                            onClick={() => window.open('https://t.me/+9Qf0bWVpF35jNWEx', '_blank')}
                        />
                        <WhatsappIcon
                            color={'#fff'}
                            width={20}
                            height={20}
                            onClick={() =>
                                window.open('https://whatsapp.com/channel/0029VawDrasLtOjKouGAfp24', '_blank')
                            }
                        />
                        {getTwitchConfiguration()}
                    </div>
                </div>
                <div className="social-networks-container__right">
                    <div>
                        <div className="social-networks-text text-left social-networks-text-left-first-row-left">
                            {!user.logged ? (
                                <Link
                                    legacyBehavior
                                    href={Rutas.INICIA_SESION}
                                    style={{ color: 'rgba(250,250,250,.7)' }}
                                    className="li-item-icon"
                                >
                                    <a className="li-item inicia-sesion-menu">
                                        <PersonIcon
                                            className="fas fa-user social-network-icon twitter-icon"
                                            onClick={handleClose}
                                        />
                                        <span
                                            className="social-networks-container__right__inicia-sesion"
                                            onClick={handleClose}
                                        >
                                            Iniciar Sesión
                                        </span>
                                    </a>
                                </Link>
                            ) : (
                                <div className="d-flex">
                                    <div className="">
                                        <Button
                                            id="nickname"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            style={{ padding: '0px' }}
                                        >
                                            {user.subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
                                                <PremiumIcon />
                                            )}
                                            <span
                                                style={{
                                                    color: '#fff',
                                                    marginRight: '7px',
                                                    marginLeft: '4px',
                                                }}
                                            >
                                                {user?.nickname || user?.email?.split('@')[0]}
                                            </span>
                                            <Avatar
                                                src={`${user.image}?width=45`}
                                                alt={user.nickname}
                                                sx={{
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        </Button>
                                        <Menu
                                            id="profile"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={closeMenu}
                                            MenuListProps={{
                                                'aria-labelledby': 'profile',
                                            }}
                                        >
                                            <Link href={Rutas.PERFIL_DE_USUARIO} passHref>
                                                <MenuItem
                                                    onClick={() => {
                                                        closeMenu();
                                                        handleClose();
                                                    }}
                                                >
                                                    <div style={{ marginRight: '5px' }}>
                                                        <UserProfileIcon />
                                                    </div>
                                                    Perfil de usuario
                                                </MenuItem>
                                            </Link>
                                            <Divider style={{ backgroundColor: '#E0E0E0' }} />
                                            <Link href={Rutas.SUSCRIPCIONES} passHref>
                                                <MenuItem
                                                    onClick={() => {
                                                        closeMenu();
                                                        handleClose();
                                                    }}
                                                >
                                                    <div style={{ marginRight: '5px' }}>
                                                        <PremiumIcon />
                                                    </div>
                                                    {user.subscriptionType === SUBSCRIPTION_TYPE.PREMIUM
                                                        ? 'Mi suscripción'
                                                        : 'Hazte Premium'}
                                                </MenuItem>
                                            </Link>
                                            <Divider style={{ backgroundColor: '#E0E0E0' }} />
                                            <MenuItem
                                                onClick={() => {
                                                    logout();
                                                    handleClose();
                                                }}
                                            >
                                                <div style={{ marginRight: '5px' }}>
                                                    <LogoutIcon />
                                                </div>
                                                Cerrar sesión
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialComponent;
