import { LEAGUES } from '@/constants/leagues';

const translations = {
    '16th Finals': 'Dieciseisavos de final',
    '1st Qualifying Round': 'Primera Ronda de Clasificación',
    '1st Round': 'Primera Ronda',
    '2nd Qualifying Round': 'Segunda Ronda de Clasificación',
    '2nd Round': 'Segunda Ronda',
    '3rd Place Final': 'Final de Tercer Lugar',
    '3rd Qualifying Round': 'Tercera Ronda de Clasificación',
    '3rd Round': 'Tercera Ronda',
    '8th Finals': 'Octavos de final',
    Final: 'Final',
    'Friendlies 1': 'Amistoso',
    'Club Friendlies 3': 'Amistoso',
    'Club Friendlies 2': 'Amistoso',
    'Club Friendlies 1': 'Amistoso',
    'Club Friendlies 4': 'Amistoso',
    'Club Friendlies 5': 'Amistoso',
    'Friendlies 2': 'Amistoso',
    'Friendlies 3': 'Amistoso',
    'Group A - 1': 'Grupo A - 1',
    'Group A - 2': 'Grupo A - 2',
    'Group A - 3': 'Grupo A - 3',
    'Group A - 4': 'Grupo A - 4',
    'Group A - 5': 'Grupo A - 5',
    'Group A - 6': 'Grupo A - 6',
    'Group B - 1': 'Grupo B - 1',
    'Group B - 2': 'Grupo B - 2',
    'Group B - 3': 'Grupo B - 3',
    'Group B - 4': 'Grupo B - 4',
    'Group B - 5': 'Grupo B - 5',
    'Group B - 6': 'Grupo B - 6',
    'Group C - 1': 'Grupo C - 1',
    'Group C - 2': 'Grupo C - 2',
    'Group C - 3': 'Grupo C - 3',
    'Group C - 4': 'Grupo C - 4',
    'Group C - 5': 'Grupo C - 5',
    'Group C - 6': 'Grupo C - 6',
    'Group D - 1': 'Grupo D - 1',
    'Group D - 2': 'Grupo D - 2',
    'Group D - 3': 'Grupo D - 3',
    'Group D - 4': 'Grupo D - 4',
    'Group D - 5': 'Grupo D - 5',
    'Group D - 6': 'Grupo D - 6',
    'Group E - 1': 'Grupo E - 1',
    'Group E - 2': 'Grupo E - 2',
    'Group E - 3': 'Grupo E - 3',
    'Group E - 4': 'Grupo E - 4',
    'Group E - 5': 'Grupo E - 5',
    'Group E - 6': 'Grupo E - 6',
    'Group F - 1': 'Grupo F - 1',
    'Group F - 2': 'Grupo F - 2',
    'Group F - 3': 'Grupo F - 3',
    'Group F - 4': 'Grupo F - 4',
    'Group F - 5': 'Grupo F - 5',
    'Group F - 6': 'Grupo F - 6',
    'Group G - 1': 'Grupo G - 1',
    'Group G - 2': 'Grupo G - 2',
    'Group G - 3': 'Grupo G - 3',
    'Group G - 4': 'Grupo G - 4',
    'Group G - 5': 'Grupo G - 5',
    'Group G - 6': 'Grupo G - 6',
    'Group H - 1': 'Grupo H - 1',
    'Group H - 2': 'Grupo H - 2',
    'Group H - 3': 'Grupo H - 3',
    'Group H - 4': 'Grupo H - 4',
    'Group H - 5': 'Grupo H - 5',
    'Group H - 6': 'Grupo H - 6',
    'Group I - 1': 'Grupo I - 1',
    'Group I - 2': 'Grupo I - 2',
    'Group I - 3': 'Grupo I - 3',
    'Group I - 4': 'Grupo I - 4',
    'Group I - 5': 'Grupo I - 5',
    'Group I - 6': 'Grupo I - 6',
    'Group J - 1': 'Grupo J - 1',
    'Group J - 2': 'Grupo J - 2',
    'Group J - 3': 'Grupo J - 3',
    'Group J - 4': 'Grupo J - 4',
    'Group J - 5': 'Grupo J - 5',
    'Group J - 6': 'Grupo J - 6',
    'Group K - 1': 'Grupo K - 1',
    'Group K - 2': 'Grupo K - 2',
    'Group K - 3': 'Grupo K - 3',
    'Group K - 4': 'Grupo K - 4',
    'Group K - 5': 'Grupo K - 5',
    'Group K - 6': 'Grupo K - 6',
    'Group L - 1': 'Grupo L - 1',
    'Group L - 2': 'Grupo L - 2',
    'Group L - 3': 'Grupo L - 3',
    'Group L - 4': 'Grupo L - 4',
    'Group L - 5': 'Grupo L - 5',
    'Group L - 6': 'Grupo L - 6',
    'Group Stage - 1': 'Fase de Grupos 1',
    'Group Stage - 2': 'Fase de Grupos 2',
    'Group Stage - 3': 'Fase de Grupos 3',
    'Group Stage - 4': 'Fase de Grupos 4',
    'Group Stage - 5': 'Fase de Grupos 5',
    'Group Stage - 6': 'Fase de Grupos 6',
    'Knockout Round Play-offs': 'Ronda de Eliminatorias - Play-offs',
    'Play-offs': 'Play-offs',
    'Preliminary Round': 'Ronda Preliminar',
    'Preliminary Round - Final': 'Ronda Preliminar - Final',
    'Preliminary Round - Semi-finals': 'Ronda Preliminar - Semifinales',
    'Preliminary round': 'Ronda Preliminar',
    'Preliminary round 1': 'Ronda Preliminar 1',
    'Preliminary round 2': 'Ronda Preliminar 2',
    'Qualifying Play-offs Path A - Semi-Finals': 'Play-offs de Clasificación - Ruta A - Semifinales',
    'Qualifying Play-offs Path B - Semi-Finals': 'Play-offs de Clasificación - Ruta B - Semifinales',
    'Qualifying Play-offs Path C - Semi-Finals': 'Play-offs de Clasificación - Ruta C - Semifinales',
    'Qualifying Round - 1': 'Ronda de Clasificación 1',
    'Qualifying Round - 10': 'Ronda de Clasificación 10',
    'Qualifying Round - 2': 'Ronda de Clasificación 2',
    'Qualifying Round - 3': 'Ronda de Clasificación 3',
    'Qualifying Round - 4': 'Ronda de Clasificación 4',
    'Qualifying Round - 5': 'Ronda de Clasificación 5',
    'Qualifying Round - 6': 'Ronda de Clasificación 6',
    'Qualifying Round - 7': 'Ronda de Clasificación 7',
    'Qualifying Round - 8': 'Ronda de Clasificación 8',
    'Qualifying Round - 9': 'Ronda de Clasificación 9',
    'Quarter-finals': 'Cuartos de final',
    'Regular Season - 1': 'Jornada 1',
    'Regular Season - 10': 'Jornada 10',
    'Regular Season - 11': 'Jornada 11',
    'Regular Season - 12': 'Jornada 12',
    'Regular Season - 13': 'Jornada 13',
    'Regular Season - 14': 'Jornada 14',
    'Regular Season - 15': 'Jornada 15',
    'Regular Season - 16': 'Jornada 16',
    'Regular Season - 17': 'Jornada 17',
    'Regular Season - 18': 'Jornada 18',
    'Regular Season - 19': 'Jornada 19',
    'Regular Season - 2': 'Jornada 2',
    'Regular Season - 20': 'Jornada 20',
    'Regular Season - 21': 'Jornada 21',
    'Regular Season - 22': 'Jornada 22',
    'Regular Season - 23': 'Jornada 23',
    'Regular Season - 24': 'Jornada 24',
    'Regular Season - 25': 'Jornada 25',
    'Regular Season - 26': 'Jornada 26',
    'Regular Season - 27': 'Jornada 27',
    'Regular Season - 28': 'Jornada 28',
    'Regular Season - 29': 'Jornada 29',
    'Regular Season - 3': 'Jornada 3',
    'Regular Season - 30': 'Jornada 30',
    'Regular Season - 31': 'Jornada 31',
    'Regular Season - 32': 'Jornada 32',
    'Regular Season - 33': 'Jornada 33',
    'Regular Season - 34': 'Jornada 34',
    'Regular Season - 35': 'Jornada 35',
    'Regular Season - 36': 'Jornada 36',
    'Regular Season - 37': 'Jornada 37',
    'Regular Season - 38': 'Jornada 38',
    'Regular Season - 4': 'Jornada 4',
    'Regular Season - 5': 'Jornada 5',
    'Regular Season - 6': 'Jornada 6',
    'Regular Season - 7': 'Jornada 7',
    'Regular Season - 8': 'Jornada 8',
    'Regular Season - 9': 'Jornada 9',
    'Round of 16': 'Octavos de final',
    'Round of 32': 'Dieciseisavos de final',
    'Semi-finals': 'Semifinales',
    'Qualifying Play-offs Path C - Final': 'Grupo C - Final Play-offs',
    'Qualifying Play-offs Path B - Final': 'Grupo B - Final Play-offs',
    'Qualifying Play-offs Path A - Final': 'Grupo A - Final Play-offs',
    'League Stage - 1': 'Primera ronda',
    'League Stage - 2': 'Segunda ronda',
    'League Stage - 3': 'Tercera ronda',
    'League Stage - 4': 'Cuarta ronda',
    'League Stage - 5': 'Quinta ronda',
    'League Stage - 6': 'Sexta ronda',
    'League Stage - 7': 'Séptima ronda',
    'League Stage - 8': 'Octava ronda',
};

const translationsShort = {
    '16th Finals': '1/16',
    '1st Qualifying Round': 'RC1',
    '1st Round': '1R',
    '2nd Qualifying Round': '2QR',
    '2nd Round': 'R2',
    '3rd Place Final': '3rd',
    '3rd Qualifying Round': 'RC3',
    '3rd Round': 'R3',
    '8th Finals': '1/8',
    Final: 'Final',
    'Friendlies 1': 'A1',
    'World: Friendly International': 'A',
    'Friendlies 2': 'A2',
    'Friendlies 3': 'A3',
    'Club Friendlies 3': 'A',
    'Club Friendlies 2': 'A',
    'Club Friendlies 1': 'A',
    'Club Friendlies 4': 'A',
    'Club Friendlies 5': 'A',
    'Group A - 1': 'GA1',
    'Group A - 2': 'GA2',
    'Group A - 3': 'GA3',
    'Group A - 4': 'GA4',
    'Group A - 5': 'GA5',
    'Group A - 6': 'GA6',
    'Group B - 1': 'GB1',
    'Group B - 2': 'GB2',
    'Group B - 3': 'GB3',
    'Group B - 4': 'GB4',
    'Group B - 5': 'GB5',
    'Group B - 6': 'GB6',
    'Group C - 1': 'GC1',
    'Group C - 2': 'GC2',
    'Group C - 3': 'GC3',
    'Group C - 4': 'GC4',
    'Group C - 5': 'GC5',
    'Group C - 6': 'GC6',
    'Group D - 1': 'GD1',
    'Group D - 2': 'GD2',
    'Group D - 3': 'GD3',
    'Group D - 4': 'GD4',
    'Group D - 5': 'GD5',
    'Group D - 6': 'GD6',
    'Group E - 1': 'GE1',
    'Group E - 2': 'GE2',
    'Group E - 3': 'GE3',
    'Group E - 4': 'GE4',
    'Group E - 5': 'GE5',
    'Group E - 6': 'GE6',
    'Group F - 1': 'GF1',
    'Group F - 2': 'GF2',
    'Group F - 3': 'GF3',
    'Group F - 4': 'GF4',
    'Group F - 5': 'GF5',
    'Group F - 6': 'GF6',
    'Group G - 1': 'GG1',
    'Group G - 2': 'GG2',
    'Group G - 3': 'GG3',
    'Group G - 4': 'GG4',
    'Group G - 5': 'GG5',
    'Group G - 6': 'GG6',
    'Group H - 1': 'GH1',
    'Group H - 2': 'GH2',
    'Group H - 3': 'GH3',
    'Group H - 4': 'GH4',
    'Group H - 5': 'GH5',
    'Group H - 6': 'GH6',
    'Group I - 1': 'GI1',
    'Group I - 2': 'GI2',
    'Group I - 3': 'GI3',
    'Group I - 4': 'GI4',
    'Group I - 5': 'GI5',
    'Group I - 6': 'GI6',
    'Group J - 1': 'GJ1',
    'Group J - 2': 'GJ2',
    'Group J - 3': 'GJ3',
    'Group J - 4': 'GJ4',
    'Group J - 5': 'GJ5',
    'Group J - 6': 'GJ6',
    'Group K - 1': 'GK1',
    'Group K - 2': 'GK2',
    'Group K - 3': 'GK3',
    'Group K - 4': 'GK4',
    'Group K - 5': 'GK5',
    'Group K - 6': 'GK6',
    'Group L - 1': 'GL1',
    'Group L - 2': 'GL2',
    'Group L - 3': 'GL3',
    'Group L - 4': 'GL4',
    'Group L - 5': 'GL5',
    'Group L - 6': 'GL6',
    'Group Stage - 1': 'FG1',
    'Group Stage - 2': 'FG2',
    'Group Stage - 3': 'FG3',
    'Group Stage - 4': 'FG4',
    'Group Stage - 5': 'FG5',
    'Group Stage - 6': 'FG6',
    'Knockout Round Play-offs': 'KRPO',
    'Play-offs': 'PO',
    'Preliminary Round': 'Prelim',
    'Preliminary Round - Final': 'PrelimF',
    'Preliminary Round - Semi-finals': 'PrelimSF',
    'Preliminary round': 'Prelim',
    'Preliminary round 1': 'Prelim1',
    'Preliminary round 2': 'Prelim2',
    'Qualifying Play-offs Path A - Semi-Finals': 'QPAP-SF',
    'Qualifying Play-offs Path B - Semi-Finals': 'QPBP-SF',
    'Qualifying Play-offs Path C - Semi-Finals': 'QPCP-SF',
    'Qualifying Round - 1': 'RC1',
    'Qualifying Round - 10': 'RC10',
    'Qualifying Round - 2': 'RC2',
    'Qualifying Round - 3': 'RC3',
    'Qualifying Round - 4': 'RC4',
    'Qualifying Round - 5': 'RC5',
    'Qualifying Round - 6': 'RC6',
    'Qualifying Round - 7': 'RC7',
    'Qualifying Round - 8': 'RC8',
    'Qualifying Round - 9': 'RC9',
    'Quarter-finals': '1/4',
    'Regular Season - 1': 'J1',
    'Regular Season - 2': 'J2',
    'Regular Season - 3': 'J3',
    'Regular Season - 4': 'J4',
    'Regular Season - 5': 'J5',
    'Regular Season - 6': 'J6',
    'Regular Season - 7': 'J7',
    'Regular Season - 8': 'J8',
    'Regular Season - 9': 'J9',
    'Regular Season - 10': 'J10',
    'Regular Season - 11': 'J11',
    'Regular Season - 12': 'J12',
    'Regular Season - 13': 'J13',
    'Regular Season - 14': 'J14',
    'Regular Season - 15': 'J15',
    'Regular Season - 16': 'J16',
    'Regular Season - 17': 'J17',
    'Regular Season - 18': 'J18',
    'Regular Season - 19': 'J19',
    'Regular Season - 20': 'J20',
    'Regular Season - 21': 'J21',
    'Regular Season - 22': 'J22',
    'Regular Season - 23': 'J23',
    'Regular Season - 24': 'J24',
    'Regular Season - 25': 'J25',
    'Regular Season - 26': 'J26',
    'Regular Season - 27': 'J27',
    'Regular Season - 28': 'J28',
    'Regular Season - 29': 'J29',
    'Regular Season - 30': 'J30',
    'Regular Season - 31': 'J31',
    'Regular Season - 32': 'J32',
    'Regular Season - 33': 'J33',
    'Regular Season - 34': 'J34',
    'Regular Season - 35': 'J35',
    'Regular Season - 36': 'J36',
    'Regular Season - 37': 'J37',
    'Regular Season - 38': 'J38',
    'Round of 16': '1/16',
    'Round of 32': '1/32',
    'Semi-finals': 'Semi',
    'Qualifying Play-offs Path C - Final': 'PO GC',
    'Qualifying Play-offs Path B - Final': 'PO GB',
    'Qualifying Play-offs Path A - Final': 'PO GA',
    'League Stage - 1': 'J1',
    'League Stage - 2': 'J2',
    'League Stage - 3': 'J3',
    'League Stage - 4': 'J4',
    'League Stage - 5': 'J5',
    'League Stage - 6': 'J6',
    'League Stage - 7': 'J7',
    'League Stage - 8': 'J8',
};

export const translateLeagues = (leagueId, text) => {
    switch (leagueId) {
        case LEAGUES.EURO_CLASIFICACION:
            return 'EC';
        case LEAGUES.FIFA_FRIENDLIES:
            return 'A';
        default:
            return text?.substring(0, 3);
    }
};

export const translate = (text, isShort) => {
    if (isShort) {
        return translationsShort[text] || text?.substring(0, 3) || text;
    }
    return translations[text] || text;
};

export const translateRecentFixtures = (text) => {
    return translationsShort[text] || text;
};

export const translateRecentFixturesLeague = (leagueId) => {
    switch (leagueId) {
        case LEAGUES.LA_LIGA:
            return 'La Liga';
        case LEAGUES.PREMIER_LEAGUE:
            return 'Premier';
        case LEAGUES.SERIE_A:
            return 'Serie A';
        case LEAGUES.PRIMEIRA_LIGA:
            return 'Primeira Liga';
        case LEAGUES.BUNDESLIGA:
            return 'Bundesliga';
        case LEAGUES.LIGUE_1:
            return 'Ligue 1';
        case LEAGUES.SEGUNDA_DIVISION:
            return '2da División';
        case LEAGUES.COPA_DEL_REY:
            return 'C. Rey';
        case LEAGUES.CHAMPIONS_LEAGUE:
            return 'Champions';
        case LEAGUES.EUROPA_LEAGUE:
            return 'E. League';
        case LEAGUES.EUROPE_SUPER_CUP:
            return 'Supercopa de Europa';
        case LEAGUES.SPAIN_SUPER_CUP:
            return 'Supercopa de España';
        case LEAGUES.FIFA_WORLD_CLUB:
            return 'Mundial de Clubes';
        case LEAGUES.NATIONS_LEAGUE:
            return 'Nations League';
        case LEAGUES.UEFA_EUROPA_CONFERENCE_LEAGUE:
            return 'Conference League';
        case LEAGUES.FIFA_FRIENDLIES:
            return 'Amistosos';
        case LEAGUES.EURO_CLASIFICACION:
            return 'Euro - Clasif.';
        case LEAGUES.EUROCOPA:
            return 'Eurocopa';
        case LEAGUES.COPA_AMERICA:
            return 'Copa América';
        default:
            return 'C';
    }
};
