import React from 'react';

import { Box, Typography, CircularProgress, Grid2 } from '@mui/material';

import AlineacionesActivityInvertedIcon from '../home/icons/alineaciones-activity-icon-inverted';
import ClockIcon from '../shared/icons/clock-icon';
import { BasicIcon } from '../shared/icons/subscriptions/basic';
import TarjetasAmarillasIcon from '../shared/icons/tarjetas/amarilla-icon';
import AsistenciasIcon from '../shared/icons/tarjetas/asistencias-icon';
import TarjetaRojaIcon from '../shared/icons/tarjetas/roja-icon';
import SegundasAmarillasIcon from '../shared/icons/tarjetas/segunda-amarillas-icon';

const JugadorFantasyStats = ({ player }) => {
    const { data } = player;

    return (
        <Box className="player-stats-container" mb={2}>
            <h2 className="jugador-fantasy-title">Estadísticas Principales</h2>
            <Grid2 container spacing={2} className="player-stats-grid">
                <Grid2 xs={6}>
                    <StatItem
                        icon={<AlineacionesActivityInvertedIcon width={20} height={20} />}
                        label="Partidos"
                        value={data.partidos}
                    />
                </Grid2>
                <Grid2 xs={6}>
                    <StatItem
                        icon={<ClockIcon width={20} height={20} />}
                        label="Minutos"
                        value={data.minutos}
                    />
                </Grid2>
                <Grid2 xs={6}>
                    <StatItem icon={<BasicIcon width={20} height={20} />} label="Goles" value={data.goles} />
                </Grid2>
                <Grid2 xs={6}>
                    <StatItem
                        icon={<AsistenciasIcon width={20} height={20} />}
                        label="Asistencias"
                        value={data.asistencias}
                    />
                </Grid2>
                <Grid2 xs={6}>
                    <StatItem
                        icon={<TarjetasAmarillasIcon width={20} height={20} />}
                        label="T. amarillas"
                        value={data.amarillas}
                    />
                </Grid2>
                <Grid2 xs={6}>
                    <StatItem
                        icon={<SegundasAmarillasIcon width={20} height={20} />}
                        label="Segundas amarillas"
                        value={data.segundasAmarillas}
                    />
                </Grid2>
                <Grid2 xs={6}>
                    <StatItem
                        icon={<TarjetaRojaIcon width={20} height={20} />}
                        label="Tarjetas rojas"
                        value={data.rojas}
                    />
                </Grid2>
            </Grid2>

            <Box className="player-stats-progress">
                <CircularIndicator label="Cuota XI inicial" value={data.titularidades} />
                <CircularIndicator label="Minutos jugados" value={data.minutosQuota} />
                <CircularIndicator label="Participaciones de gol" value={data.participacionGoles} />
            </Box>
        </Box>
    );
};

// Componente para cada estadística
const StatItem = ({ icon, label, value }) => (
    <Box className="player-stat-item">
        <Box display={'flex'} gap={'4px'} alignItems={'center'}>
            <Box variant="subtitle2" className="player-stat-label">
                {icon}
                <span style={{ marginLeft: '4px' }}>{label}</span>
            </Box>
        </Box>
        <Typography variant="body1" className="player-stat-value">
            {value}
        </Typography>
    </Box>
);

// Componente para indicadores circulares
export const CircularIndicator = ({ label, data, size = 80, thickness = 4 }) => {
    const value = data?.porcentaje ?? data;
    const minutosJugados = data?.minutosJugados ?? 0;
    const totalMinutos = data?.totalMinutos ?? 0;

    const getColor = (value) => {
        if (value > 80) return '#1976D2'; // Azul
        if (value > 60) return '#388E3C'; // Verde
        if (value > 40) return '#F57C00'; // Naranja
        return '#D32F2F'; // Rojo
    };

    return (
        <Box className="player-circular-indicator">
            <Box className="player-circular-progress-container">
                <CircularProgress
                    variant="determinate"
                    value={value}
                    size={size}
                    thickness={thickness}
                    className="player-circular-progress"
                    sx={{ color: getColor(value) }}
                />
                <Typography variant="h6" className="player-circular-value">
                    {value}%
                </Typography>
            </Box>
            <Typography fontSize={12} mb={0}>
                {minutosJugados} / {totalMinutos}
            </Typography>
            <Typography fontSize={10}>{label}</Typography>
        </Box>
    );
};

export default JugadorFantasyStats;
