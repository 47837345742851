const InfoIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 24}
            height={iconHeight ?? iconWidth ?? 24}
            viewBox="0 0 48 48"
        >
            <circle cx="24" cy="24" r="21" fill="#2196F3" />
            <path fill="#fff" d="M22 22h4v11h-4z" />
            <circle cx="24" cy="16.5" r="2.5" fill="#fff" />
        </svg>
    );
};

export default InfoIcon;
