const FantasyLaLigaIcon = ({ iconWidth, iconHeight, style }) => {
    return (
        <svg
            width={iconWidth ?? 30}
            height={iconHeight ?? iconWidth ?? 30}
            viewBox="0 0 70 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            alt="LALIGA logotipo"
            style={style}
        >
            <path
                d="M2.91 25.173L20.332 0h16.854L15.497 30.861h14.08L7.675 42.026l-4.41-5.618C1.701 34.346.99 32.782.99 30.648c0-1.92.712-3.77 1.92-5.475zM17.203 51.2c0-1.778.712-3.698 1.99-5.547L51.265 0h18.56L33.841 51.2h16.213L24.882 64l-5.405-6.897c-1.493-1.92-2.275-3.84-2.275-5.902l.001-.002z"
                fill="#FF4B44"
            ></path>
        </svg>
    );
};

export default FantasyLaLigaIcon;
