import { Box, Typography } from '@mui/material';

const MinutesAtionsGeneralInfoPlayerComponent = ({ partidos }) => {
    const totalJornadas = partidos?.length ?? 0;
    // Contadores de titular, suplente y no jugados
    const titularidad = partidos.filter((p) => !p.substitute && Number(p.minutes ?? 0) > 0).length;
    const suplencias = partidos.filter((p) => p.substitute).length;
    const noJugados = totalJornadas - (titularidad + suplencias);

    // Calcular porcentajes
    const titularidadPorcentaje = ((titularidad / totalJornadas) * 100).toFixed(1);
    const suplenciasPorcentaje = ((suplencias / totalJornadas) * 100).toFixed(1);
    const noJugadosPorcentaje = ((noJugados / totalJornadas) * 100).toFixed(1);

    const rojo = '#D32F2F';
    const naranja = '#F57C00';
    const verde = '#388E3C';
    const azul = '#1976D2';

    const stats = [
        {
            label: 'Titularidades',
            value: `${titularidad}/${totalJornadas}`,
            porcentaje: `${titularidadPorcentaje}%`,
        },
        {
            label: 'Suplencias',
            value: `${suplencias}/${totalJornadas}`,
            porcentaje: `${suplenciasPorcentaje}%`,
        },
        {
            label: 'No jugados',
            value: `${noJugados}/${totalJornadas}`,
            porcentaje: `${noJugadosPorcentaje}%`,
        },
    ];

    const getColor = (label) => {
        if (label === 'Titularidades' || label === 'Suplencias') {
            if (titularidadPorcentaje > 80) return azul; // Azul (Excelente)
            else if (titularidadPorcentaje > 60) return verde; // Verde (Bueno)
            else if (titularidadPorcentaje > 40) return naranja; // Naranja (Regular)
            else return rojo; // Rojo (Muy malo)
        }

        // Suplencias heredan el mismo color de Titularidades
        if (label === 'No jugados') {
            // No jugados (más alto es peor, colores invertidos)
            if (noJugadosPorcentaje > 60) return rojo; // Rojo (Muy malo)
            if (noJugadosPorcentaje > 40) return naranja; // Naranja (Regular)
            if (noJugadosPorcentaje > 20) return verde; // Verde (Bueno)
            return azul; // Azul (Excelente)
        }

        return color; // Titularidades conserva el color calculado
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                boxShadow: '0 2px 8px rgba(0,0,0,.1)',
                mb: 2,
            }}
        >
            {stats.map((item, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '8px 12px',
                        borderRadius: '4px',
                        width: '33.333333%',
                    }}
                >
                    <Typography fontWeight="bold">{item.label}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ fontSize: '14px' }}>{item.value}</Typography>
                        <Typography
                            sx={{
                                backgroundColor: getColor(item.label),
                                color: '#fff',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                fontSize: '12px',
                            }}
                        >
                            {item.porcentaje}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default MinutesAtionsGeneralInfoPlayerComponent;
