import { Box, Typography } from '@mui/material';
import Link from 'next/link';

import CustomImageComponent from '@/components/shared/custom/custom-image';
import AirPlaneIcon from '@/components/shared/icons/airplane-out-icon';
import HomeIcon from '@/components/shared/icons/home-icon';
import { Rutas } from '@/constants/rutas';
import { translate } from '@/data/fixture-transalations';
import { getDate_dd_MM } from '@/helpers/datetime-helper';
import { getCssClass } from '@/helpers/fixtures-helper';
import { getTeamImageById } from '@/helpers/images-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';

const GetFixtureBox = ({ fixtures, id, isLastFixtures }) => {
    if (fixtures?.length > 0) {
        return fixtures.map((fixture, index) => {
            const cssClass = getCssClass(fixture);

            const rival = fixture?.home?.id === id ? fixture?.away : fixture?.home;
            let src = rival?.image;

            if (rival?.id) {
                src = getTeamImageById(rival?.id, 35);
            }
            const icon = fixture?.home?.id === id ? <HomeIcon width={15} /> : <AirPlaneIcon width={15} />;

            return (
                <Link
                    key={index}
                    className="last-fixtures__box"
                    style={{
                        width: `${100 / fixtures.length}%`,
                        cursor: 'pointer',
                        borderRight: index !== fixtures?.length - 1 ? '1px solid #ccc' : 'none',
                    }}
                    href={`${Rutas.PARTIDO}/${fixture.fixtureId ?? fixture?.id}/alineaciones-probables`}
                >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap="4px">
                        {icon}
                        <Typography style={{ fontSize: '13px' }}>{getDate_dd_MM(fixture?.date)}</Typography>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '8px',
                            paddingLeft: '6px',
                            paddingRight: '6px',
                        }}
                        variant="div"
                    >
                        <CustomImageComponent
                            key={index}
                            src={src}
                            width={25}
                            height={25}
                            alt={rival.name}
                        ></CustomImageComponent>
                        {isLastFixtures && (
                            <Box
                                className={cssClass}
                                style={{ display: 'flex', padding: '0 4px', borderRadius: '4px' }}
                            >
                                <span style={{ fontSize: '12px' }}>{fixture?.home?.goals}</span>
                                <span style={{ fontSize: '12px' }}>-</span>
                                <span style={{ fontSize: '12px' }}>{fixture?.away?.goals}</span>
                            </Box>
                        )}
                    </Box>
                    <Box style={{ display: 'flex', marginTop: '4px', alignItems: 'center' }}>
                        <CustomImageComponent
                            src={getCompetitionImage(fixture?.leagueId, 15)}
                            width={15}
                            height={15}
                            alt="competición"
                            title="competición"
                            style={{ marginRight: '4px' }}
                        />
                        <Typography style={{ fontSize: '14px' }}>{translate(fixture.round, true)}</Typography>
                    </Box>
                </Link>
            );
        });
    }
};

const FixturesPreview = ({
    fixtures,
    isLastFixtures,
    title,
    id,
    setTabIndex,
    mostrarVerMas = true,
    styles = {},
}) => {
    return (
        <Box className="af-card last-fixtures" style={styles}>
            <Box className="af-card__head">
                <Typography className="af-card__head-text" variant="h6">
                    {title}
                </Typography>
                {mostrarVerMas && (
                    <Typography className="af-card__head-link" variant="h6" onClick={() => setTabIndex(2)}>
                        Ver más
                    </Typography>
                )}
            </Box>
            <Box className="af-card__body last-fixtures__body">
                <GetFixtureBox fixtures={fixtures} id={id} isLastFixtures={isLastFixtures} />
            </Box>
        </Box>
    );
};

export default FixturesPreview;
