import { useEffect } from 'react';

import { SITE_USER_COOKIE } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { removeCleverCoreScript } from '@/helpers/ads-helper';
import { getFromLocalStorage } from '@/helpers/cookies';

export const MoneyAdType = {
    MEGABANNER: 1, // 728x90
    ROBAPAGINAS: 2, // 300x250
    SPLITSCREEN: 3,
    SKYSCRAPER: 4,
    SLIDE_IN: 6,
    IN_TEXT: 11, // NO RECOMENDADO
    INTERSITIAL: 15,
    CONTENIDO_RECOMENDADO: 16,
    ROBAPAGINAS_INFERIOR: 19,
    MEGASKYSCRAPER: 20,
    MEGABANNER_INFERIOR: 28,
    BILLBOARD: 31,
    CORNER_VIDEO: 38,
};

const MoneyAd = ({ type }) => {
    const isProd = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'development';

    useEffect(() => {
        const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
        const data = JSON.parse(dataFromCookies);
        if (isProd && data?.subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
            try {
                const container = document.getElementById(`98781-${type}`);

                // Si no se encuentra el contenedor, no agregar scripts
                if (!container) return;

                // Crear y agregar el primer script
                const genScript = document.createElement('script');
                genScript.src = `//ads.themoneytizer.com/s/gen.js?type=${type}`;
                genScript.async = true;
                container.appendChild(genScript);

                // Crear y agregar el segundo script
                const reqScript = document.createElement('script');
                reqScript.src = `//ads.themoneytizer.com/s/requestform.js?siteId=98781&formatId=${type}`;
                reqScript.async = true;
                container.appendChild(reqScript);

                return () => {
                    container.innerHTML = '';
                };
            } catch (e) {
                console.log(e);
            }
        }

        return () => {
            removeCleverCoreScript();
        };
    }, [isProd, type]);

    if (!isProd) {
        return null;
    }

    return (
        <div className="money-ad-parent">
            <div className={`money-ad-${type}`} id={`98781-${type}`}></div>
        </div>
    );
};

export default MoneyAd;
