import React from 'react';

import CustomImageComponent from '../../custom/custom-image';

const TarjetaRojaIcon = ({ width, height }) => {
    return (
        <CustomImageComponent
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxNi41Ij48cGF0aCBzdHlsZT0iZmlsbDojOGYxYTI5IiBkPSJNMCAwaDEydjE2LjVIMHoiLz48L3N2Zz4="
            alt="Tarjetas rojas"
            width={width ?? 24}
            height={height ?? 24}
        />
    );
};

export default TarjetaRojaIcon;
