import React from 'react';

import CustomImageComponent from '../../custom/custom-image';

const SegundasAmarillasIcon = ({ width, height }) => {
    return (
        <CustomImageComponent
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxNi41Ij48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIHN0eWxlPSJmaWxsOm5vbmUiIGQ9Ik0wIDBoMTJ2MTYuNUgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIHN0eWxlPSJjbGlwLXBhdGg6dXJsKCNhKSI+PHBhdGggZD0iTTMzMDIuOTgxIDI2NTVoLTEydjE2LjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzI5MC45ODEgLTI2NTUpIiBzdHlsZT0iZmlsbDojZjhkODI3Ii8+PHBhdGggZD0iTTEyIDBIMHYxNi41WiIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwIDYgOC4yNSkiIHN0eWxlPSJmaWxsOiM4ZjFhMjkiLz48L2c+PC9zdmc+"
            alt="Segundas Tarjetas Amarillas"
            width={width ?? 24}
            height={height ?? 24}
        />
    );
};

export default SegundasAmarillasIcon;
