import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const MinutesActionsHeaderPlayerComponent = ({ handleClose }) => {
    return (
        <section className="master-player-header" style={{ borderBottom: '1px solid rgba(255,255,255,.2)' }}>
            <Typography>Minutos disputados</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
        </section>
    );
};

export default MinutesActionsHeaderPlayerComponent;
