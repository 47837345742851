const ClockIcon = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 15}
            height={height ?? width ?? 15}
            viewBox="0 0 128 128"
        >
            <path
                fill="#fff"
                d="M64 121.73C32.16 121.73 6.27 95.84 6.27 64S32.16 6.27 64 6.27c31.83 0 57.73 25.89 57.73 57.73S95.84 121.73 64 121.73"
            />
            <path
                fill="#40c0e7"
                d="M64 .1C28.77.1.1 28.77.1 64s28.67 63.9 63.9 63.9s63.9-28.67 63.9-63.9S99.24.1 64 .1m0 121.63C32.16 121.73 6.27 95.84 6.27 64S32.16 6.27 64 6.27c31.83 0 57.73 25.89 57.73 57.73S95.84 121.73 64 121.73"
            />
            <path
                fill="#2f2f2f"
                d="M68.61 55.42V11.33H59.4v44.08c-3.07 1.64-5.18 4.85-5.18 8.57c0 .11.02.21.03.32L32.87 76.65l4.6 7.98l21.39-12.35c1.5.93 3.25 1.5 5.15 1.5c5.4 0 9.78-4.37 9.78-9.79c0-3.73-2.12-6.92-5.18-8.57M67.69 64c0 1.52-.92 2.82-2.24 3.39c-.02.01-.04.01-.06.02c-.43.18-.9.28-1.39.28c-.5 0-.96-.11-1.4-.28c-.02 0-.04-.01-.06-.02A3.7 3.7 0 0 1 60.31 64c0-2.03 1.65-3.68 3.69-3.68c.87 0 1.66.32 2.29.82c.71.56 1.21 1.38 1.34 2.32c.02.18.06.35.06.54m46.24-3.68a3.685 3.685 0 0 0 0 7.37c2.03 0 3.69-1.65 3.69-3.69c0-2.03-1.66-3.68-3.69-3.68m-4.85-18.1c1.76-1.01 2.36-3.27 1.35-5.03c-1.01-1.77-3.27-2.36-5.03-1.35a3.69 3.69 0 0 0-1.35 5.03a3.673 3.673 0 0 0 5.03 1.35M90.81 17.56c-1.76-1.01-4.02-.41-5.03 1.35a3.69 3.69 0 0 0 1.35 5.04a3.7 3.7 0 0 0 5.04-1.35c1-1.76.4-4.02-1.36-5.04m-49.93 6.38a3.67 3.67 0 0 0 1.34-5.03a3.69 3.69 0 0 0-5.03-1.35a3.673 3.673 0 0 0-1.35 5.03a3.685 3.685 0 0 0 5.04 1.35M22.6 35.84a3.67 3.67 0 0 0-5.03 1.35a3.68 3.68 0 0 0 1.35 5.03a3.68 3.68 0 0 0 5.03-1.35c1.02-1.76.41-4.01-1.35-5.03m-8.53 24.48c-2.03 0-3.68 1.65-3.68 3.68s1.65 3.68 3.68 3.68s3.68-1.65 3.68-3.68c0-2.04-1.65-3.68-3.68-3.68m4.84 25.46a3.69 3.69 0 0 0-1.35 5.03a3.68 3.68 0 0 0 5.03 1.35a3.67 3.67 0 0 0 1.35-5.03a3.67 3.67 0 0 0-5.03-1.35m21.96 18.28a3.67 3.67 0 0 0-5.03 1.35a3.69 3.69 0 0 0 1.35 5.03a3.68 3.68 0 0 0 5.03-1.35a3.68 3.68 0 0 0-1.35-5.03m23.12 6.19c-2.04 0-3.68 1.65-3.68 3.68s1.65 3.68 3.68 3.68a3.67 3.67 0 0 0 3.68-3.68c.02-2.03-1.64-3.68-3.68-3.68m23.13-6.19a3.673 3.673 0 0 0-1.35 5.03a3.69 3.69 0 0 0 5.03 1.35a3.67 3.67 0 0 0 1.35-5.03a3.67 3.67 0 0 0-5.03-1.35m21.96-18.28c-1.76-1.01-4.02-.41-5.03 1.35a3.67 3.67 0 0 0 1.35 5.03c1.76 1.01 4.01.42 5.03-1.35c1.02-1.76.42-4.01-1.35-5.03"
            />
        </svg>
    );
};

export default ClockIcon;
