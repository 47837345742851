import { useEffect, useState } from 'react';

const DateTimeWrapper = ({ date, format, style = {} }) => {
    const [localDate, setLocalDate] = useState(date);

    useEffect(() => {
        let newLocalDate = format(date);
        setLocalDate(newLocalDate);
    }, [date]);

    return <span style={style}>{localDate}</span>;
};

export default DateTimeWrapper;
