import { useContext } from 'react';

import { Box, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';
import { formatDateToDDMMYYYY, getAgeFromDate } from '@/helpers/datetime-helper';
import { getMasterPhotoById, getTeamImageById } from '@/helpers/images-helper';
import URL_FACTORY from '@/helpers/url-helper';

import HbAdComponent, { HbAdType } from '../ads-components/hb-agency/hb-ad';
import CustomImageComponent from '../shared/custom/custom-image';
import PlayerInfoCardStatus from '../shared/player-infocard-status';
import PositionBox from '../shared/position-box';

import FantasySelectorComponent from './fantasy-selector-tabs';
import MasterPlayerFantasy from './master-player-fantasy';
import NextRivalsTablesComponent from './next-rivals-tabs';

const MasterPlayerCardComponent = ({
    player,
    classes,
    hideStatus,
    hideNextRival,
    hideFantasySelector,
    isDrawer,
}) => {
    const { dispatch } = useContext(Context);

    const getConvocatoriaSeleccion = (player) => {
        if (player?.convocatoriaSeleccion?.isConvocado) {
            return (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '16px',
                            background: '#fdeded',
                            padding: '4px 8px',
                        }}
                    >
                        <div>
                            <Typography>Convocado con: </Typography>
                        </div>
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <CustomImageComponent
                                src={URL_FACTORY.GetCountryImage(
                                    player?.convocatoriaSeleccion?.countryCode,
                                    30
                                )}
                                width={20}
                                height={20}
                                alt="selección"
                                style={{ borderRadius: '50%' }}
                            />
                            <Typography style={{ margin: '0' }}>
                                {player?.convocatoriaSeleccion?.countryName}
                            </Typography>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const getPlayerInfoGeneral = (player) => {
        return (
            <div className={`player-info-card__info`}>
                <Typography className="player-info-card__text">
                    Edad:
                    <span style={{ marginLeft: '4px' }}>
                        {getAgeFromDate(player?.information?.birth?.date)}
                        <i style={{ fontSize: '12px', marginLeft: '2px' }}>
                            ({formatDateToDDMMYYYY(player?.information?.birth?.date)})
                        </i>
                    </span>
                </Typography>

                <Typography className="player-info-card__text">
                    Estatura: <span style={{ marginLeft: '4px' }}>{player?.information?.height}</span>
                </Typography>

                <Typography className="player-info-card__text">
                    Peso: <span style={{ marginLeft: '4px' }}>{player?.information?.weight}</span>
                </Typography>
                <Typography className="player-info-card__text">
                    Nacimiento:{' '}
                    <span style={{ marginLeft: '4px' }}>
                        {player?.information?.birth?.place} - {player?.information?.birth?.country}
                    </span>
                </Typography>

                {hideStatus !== true && <PlayerInfoCardStatus player={player} />}
            </div>
        );
    };

    return (
        <div className={`master-player-card`}>
            <DialogContent>
                <div>
                    <div className={`${classes ?? ''} player-info-card`}>
                        <div
                            className="player-info-card__image"
                            style={{ position: 'relative' }}
                            onClick={() => dispatch({ type: REDUCERS.HIDE_PLAYER_PROFILE })}
                        >
                            <CustomImageComponent
                                src={getMasterPhotoById(player?.information?.id, 192)}
                                alt={`${player?.information?.name} - fantasy - ${player?.fantasyPlayer?.team?.name}`}
                                width={128}
                                height={128}
                                priority={true}
                            />
                            <Typography className="player-info-card__name">
                                {player?.information?.name}
                            </Typography>

                            <Box position={'absolute'} left={'8px'} top={'6px'}>
                                <CustomImageComponent
                                    src={getTeamImageById(player?.teamId ?? player?.team?.id, 33)}
                                    alt={'jugador'}
                                    width={22}
                                    height={22}
                                    style={{ marginRight: '5px' }}
                                />
                            </Box>

                            <Box position={'absolute'} right={'8px'} top={'8px'}>
                                <PositionBox
                                    positionId={player?.information?.positionId}
                                    fontSize={10}
                                    styles={{ width: '18px', height: '18px' }}
                                />
                            </Box>
                        </div>

                        {getPlayerInfoGeneral(player)}
                    </div>
                    <HbAdComponent type={HbAdType.MOBILE_LEADERBOARD} />
                    {hideNextRival !== true && (
                        <NextRivalsTablesComponent player={player} isDrawer={isDrawer} />
                    )}
                    {hideFantasySelector !== true && <FantasySelectorComponent />}

                    <MasterPlayerFantasy player={player} />
                    {getConvocatoriaSeleccion(player)}
                </div>
            </DialogContent>
        </div>
    );
};

export default MasterPlayerCardComponent;
