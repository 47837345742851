const NovedadesIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 20}
            height={iconHeight ?? 20}
            viewBox="0 0 32 32"
        >
            <defs>
                <path
                    id="IconifyId1946b0009215f7fb90"
                    d="M6.786 12.128a.75.75 0 0 0-1.388.395v6.454a.75.75 0 0 0 1.5 0v-3.381c0-.126.165-.173.231-.066l2.382 3.842a.75.75 0 0 0 1.387-.395v-6.454a.75.75 0 0 0-1.5 0v3.381c0 .126-.165.173-.231.066zm6.336-.315a.75.75 0 0 0-.75.75v6.408c0 .414.336.75.75.75h3.633a.75.75 0 0 0 0-1.5h-2.758a.125.125 0 0 1-.125-.125v-1.33c0-.069.056-.125.125-.125h2.709a.75.75 0 0 0 0-1.5h-2.709a.125.125 0 0 1-.125-.125v-1.578c0-.069.056-.125.125-.125h2.758a.75.75 0 0 0 0-1.5zm5.981.051a.75.75 0 0 1 .924.52l.925 3.323c.034.12.206.122.24 0l.97-3.33a.75.75 0 0 1 1.438-.009l1.01 3.328c.037.12.208.117.24-.005l.87-3.296a.75.75 0 1 1 1.45.382l-1.656 6.281a.75.75 0 0 1-1.443.027L23.02 15.62c-.036-.118-.205-.117-.24.002l-1.006 3.455a.75.75 0 0 1-1.442-.009l-1.75-6.28a.75.75 0 0 1 .521-.925"
                />
            </defs>
            <g fill="none">
                <g filter="url(#IconifyId1946b0009215f7fb91)">
                    <rect
                        width="27.875"
                        height="27.875"
                        x="1.934"
                        y="1.813"
                        fill="url(#IconifyId1946b0009215f7fb96)"
                        rx="3.6"
                    />
                    <rect
                        width="27.875"
                        height="27.875"
                        x="1.934"
                        y="1.813"
                        fill="url(#IconifyId1946b0009215f7fb99)"
                        rx="3.6"
                    />
                </g>
                <g filter="url(#IconifyId1946b0009215f7fb92)">
                    <path
                        stroke="url(#IconifyId1946b0009215f7fb97)"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M28.122 4.313v22.875"
                    />
                </g>
                <g filter="url(#IconifyId1946b0009215f7fb93)">
                    <path
                        stroke="url(#IconifyId1946b0009215f7fb98)"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M5.398 3.625H27.18"
                    />
                </g>
                <g fill="#579FFF" filter="url(#IconifyId1946b0009215f7fb94)">
                    <use href="#IconifyId1946b0009215f7fb90" />
                </g>
                <g fill="#FCF2FF" filter="url(#IconifyId1946b0009215f7fb95)">
                    <use href="#IconifyId1946b0009215f7fb90" />
                </g>
                <defs>
                    <filter
                        id="IconifyId1946b0009215f7fb91"
                        width="28.875"
                        height="28.875"
                        x="1.934"
                        y=".813"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dx="1" dy="-1" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                        <feColorMatrix values="0 0 0 0 0.188235 0 0 0 0 0.470588 0 0 0 0 0.843137 0 0 0 1 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_18590_2408" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="-1" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                        <feColorMatrix values="0 0 0 0 0.27451 0 0 0 0 0.34902 0 0 0 0 0.8 0 0 0 1 0" />
                        <feBlend
                            in2="effect1_innerShadow_18590_2408"
                            result="effect2_innerShadow_18590_2408"
                        />
                    </filter>
                    <filter
                        id="IconifyId1946b0009215f7fb92"
                        width="5.5"
                        height="28.375"
                        x="25.372"
                        y="1.563"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur result="effect1_foregroundBlur_18590_2408" stdDeviation="1" />
                    </filter>
                    <filter
                        id="IconifyId1946b0009215f7fb93"
                        width="27.281"
                        height="5.5"
                        x="2.648"
                        y=".875"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur result="effect1_foregroundBlur_18590_2408" stdDeviation="1" />
                    </filter>
                    <filter
                        id="IconifyId1946b0009215f7fb94"
                        width="23.797"
                        height="9.953"
                        x="4.398"
                        y="10.773"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur result="effect1_foregroundBlur_18590_2408" stdDeviation=".5" />
                    </filter>
                    <filter
                        id="IconifyId1946b0009215f7fb95"
                        width="23.197"
                        height="9.353"
                        x="4.398"
                        y="11.373"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dx="-1" dy="1" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                        <feColorMatrix values="0 0 0 0 0.866667 0 0 0 0 0.819608 0 0 0 0 0.941176 0 0 0 1 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_18590_2408" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dx=".4" dy="-.4" />
                        <feGaussianBlur stdDeviation=".5" />
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 1 0" />
                        <feBlend
                            in2="effect1_innerShadow_18590_2408"
                            result="effect2_innerShadow_18590_2408"
                        />
                    </filter>
                    <linearGradient
                        id="IconifyId1946b0009215f7fb96"
                        x1="15.872"
                        x2="15.872"
                        y1="5.387"
                        y2="38.64"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5CB7FF" />
                        <stop offset="1" stopColor="#4878DD" />
                    </linearGradient>
                    <linearGradient
                        id="IconifyId1946b0009215f7fb97"
                        x1="28.622"
                        x2="28.622"
                        y1="4.313"
                        y2="27.188"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7FD9FF" />
                        <stop offset="1" stopColor="#639DF4" />
                    </linearGradient>
                    <linearGradient
                        id="IconifyId1946b0009215f7fb98"
                        x1="28.336"
                        x2="2.804"
                        y1="3.875"
                        y2="3.875"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7DD8FF" />
                        <stop offset="1" stopColor="#5DB6FF" />
                    </linearGradient>
                    <radialGradient
                        id="IconifyId1946b0009215f7fb99"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="matrix(-1.56249 1.46876 -1.71548 -1.82495 27.59 3.906)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7BD7FF" />
                        <stop offset="1" stopColor="#7BD7FF" stopOpacity="0" />
                    </radialGradient>
                </defs>
            </g>
        </svg>
    );
};

export default NovedadesIcon;
