const MinutesIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? '25px'}
            height={iconHeight ?? '25px'}
            viewBox="0 0 16 16"
        >
            <path
                fill="currentColor"
                d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m2.293 11.707L7 8.414V4h2v3.586l2.707 2.707z"
            />
        </svg>
    );
};

export default MinutesIcon;
