import { Fragment, useContext, useEffect, useState } from 'react';

import { Box, Drawer } from '@mui/material';

import axiosApiInstance from '@/api/shared/axios-instance';
import { LEAGUES } from '@/constants/leagues';
import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';
import { EVENTS_ACTIONS, EVENTS_CATEGORIES, EVENTS_LABELS, logEventTracker } from '@/helpers/events-helper';
import URL_FACTORY from '@/helpers/url-helper';

import MinutesActionsCardPlayerComponent from './minutes-actions-card-player';
import MinutesAtionsGeneralInfoPlayerComponent from './minutes-actions-general-info-player';
import MinutesActionsGraphicalPlayerComponent from './minutes-actions-graphical-player';
import MinutesActionsHeaderPlayerComponent from './minutes-actions-header-player';
import MinutesActionsMatchesPlayerComponent from './minutes-actions-matches-player';
import MinutesActionsTabsPlayerComponent from './minutes-actions-tabs-player';

const MinutesActionsPlayerComponent = () => {
    const [open, setOpen] = useState(false);
    const [player, setPlayer] = useState(null);
    const { state, dispatch } = useContext(Context);
    const [cache, setCache] = useState([]);
    const [anchor] = useState('right');
    const [selectedLeague, setSelectedLeague] = useState(LEAGUES.LA_LIGA); // Inicia con "Todas"
    const [partidosSeleccionados, setPartidosSeleccionados] = useState([]);

    useEffect(() => {
        if (player?.partidos?.length > 0) {
            const partidosDelJugador = player?.partidos
                ?.filter(x => x.leagueId === selectedLeague || selectedLeague === "all")
                ?.sort((a, b) => new Date(b.date) - new Date(a.date)) ?? [];
            setPartidosSeleccionados(partidosDelJugador);
        }
    }, [selectedLeague, player]);

    const handleClose = () => {
        setOpen(false);
        dispatch({ type: REDUCERS.SHOW_PLAYER_MINUTES, payload: null });
    };

    useEffect(() => {
        const playerId = state.playerMinutes;
        if (playerId) {
            const isCached = cache?.find(
                (x) =>
                    (x.playerId == playerId && x.playerId > 0)
            );

            if (isCached) {
                setOpen(true);
                setPlayer(isCached.data);
                logEventTracker(EVENTS_ACTIONS.MASTER_PLAYER, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.CACHE);
                return;
            }

            const url = URL_FACTORY.GetPlayerMinutes;

            const payload = {
                playerId: playerId,
            };

            dispatch({ type: REDUCERS.SHOW_SPINNER });

            logEventTracker(EVENTS_ACTIONS.MASTER_PLAYER, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.API);

            axiosApiInstance
                .post(url, payload)
                .then((response) => {
                    if (response?.data?.information) {
                        setOpen(true);
                        setPlayer(response?.data);

                        // Add response to cache
                        setCache((prevCache) => [
                            ...prevCache,
                            {
                                playerId: response?.data.information?.id,
                                data: response?.data,
                            },
                        ]);
                    }
                })
                .finally(() => {
                    dispatch({ type: REDUCERS.HIDE_SPINNER });
                });
        } else {
            setOpen(false);
        }
    }, [state.playerMinutes]);

    const renderModal = () => {

        if (!player || !open) return null;

        return (
            <Drawer
                anchor={anchor}
                open={open}
                onClose={() => handleClose(anchor, false)}
                className="master-player-modal"
                transitionDuration={300}
            >
                <Box sx={{ backgroundColor: '#fafafa' }}>
                    <MinutesActionsHeaderPlayerComponent handleClose={handleClose} />
                    <MinutesActionsCardPlayerComponent player={player} />
                    <MinutesActionsTabsPlayerComponent partidos={player?.partidos ?? []} selectedLeague={selectedLeague} setSelectedLeague={setSelectedLeague} />
                    <MinutesAtionsGeneralInfoPlayerComponent partidos={partidosSeleccionados} totalJornadas={38} />
                    <MinutesActionsMatchesPlayerComponent partidos={partidosSeleccionados} />
                    <MinutesActionsGraphicalPlayerComponent partidos={partidosSeleccionados} />
                </Box>
            </Drawer>
        );
    };

    return <Fragment>{renderModal()}</Fragment>;
};

export default MinutesActionsPlayerComponent;
