const SalonDeLaFamaIcon = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 25} height={height ?? 25} viewBox="0 0 14 14">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                <path d="M7 8.5v5m-2.5 0h5m-6-8a3 3 0 0 1-3-3v-1H4v4Zm7 0a3 3 0 0 0 3-3v-1H10v4Z" />
                <path d="M10 5.5a3 3 0 0 1-6 0v-5h6Z" />
            </g>
        </svg>
    );
};

export default SalonDeLaFamaIcon;
