import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { CircularIndicator } from '@/components/jugador-fantasy/jugador-fantasy-stats';

const MinutesActionsMatchesPlayerComponent = ({ partidos }) => {
    const calcularPorcentaje = (cantidadPartidos) => {
        // Obtener los minutos jugados en los últimos "cantidadPartidos"
        const minutosJugados = partidos
            .slice(0, cantidadPartidos)
            .reduce((total, partido) => total + Number(partido?.minutes ?? 0), 0);
        // Calcular el porcentaje en base a la cantidad total de minutos posibles (cantidadPartidos * 90)
        return {
            porcentaje: ((minutosJugados / (cantidadPartidos * 90)) * 100).toFixed(0),
            minutosJugados: minutosJugados,
            totalMinutos: cantidadPartidos * 90,
        }; // Redondeado a 1 decimal
    };

    return (
        <Box style={{ padding: '10px', boxShadow: '0 2px 8px rgba(0,0,0,.1)', marginBottom: '16px' }}>
            <Typography sx={{ color: 'hsl(265, 4%, 42%)', marginBottom: '8px', fontSize: '14px' }}>
                Porcentaje de minutos disputados
            </Typography>

            <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <CircularIndicator label="Último partido" data={calcularPorcentaje(1)} size={50} />
                <CircularIndicator label="Últimos 3 partidos" data={calcularPorcentaje(3)} size={50} />
                <CircularIndicator label="Últimos 5 partidos" data={calcularPorcentaje(5)} size={50} />
                <CircularIndicator label="Últimos 10 partidos" data={calcularPorcentaje(10)} size={50} />
            </Box>
        </Box>
    );
};

export default MinutesActionsMatchesPlayerComponent;
